@import url('https://fonts.googleapis.com/css2?family=Bellefair&family=Secular+One&family=Syne+Mono&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bellefair&family=Cormorant+Garamond:wght@300;400;500;600&family=Secular+One&family=Syne+Mono&display=swap');
@import url("https://use.typekit.net/xky3jqu.css");


@font-face {
  font-family: "Nimbus";
  font-weight: normal;
  src: url("graphics/fonts/NimbusSanL-Reg.otf");
}
@font-face {
  font-family: "Nimbus";
  font-weight: bold;
  src: url("graphics/fonts/NimbusSanL-Bol.otf");
}
.App {
  text-align: center;
}
body {
  background: #fff;
  color: #000;
  font-family: elza-text;
  min-height: ;
}
a {
  text-decoration: none;
  color: #000;
}
.bar {
  text-align: left;
  position: relative;
  top: 0px;
  left:0;
  width: 100%;
  padding-top: 25px;
  padding-bottom: 25px;
  margin: 0 auto;
  vertical-align: middle;
  margin-bottom: 0px;
  overflow:hidden;
  line-height: 200%;
}
.bar .left {
  width: 20%;
  float: left;
}
.bar .center {
  width: 60%;
  float: left;
  text-align: center;
}
.bar .right {
  width: 20%;
  float: right;
  text-align: right;
}
.bar .right .item {
  padding: 0px;
}
.bar .right .item:last-of-type {
  padding-right: 30px;
}
.bar .right .item .social-icon {
  width: 30px !important;
  height: 30px !important;
}
.bar img {
  width: 70px;
}
.bar .item {
  text-transform: ;
  display: inline-block;
  padding: 0px 30px 0px 30px;
  font-size: 17px;
  vertical-align: middle;
  width: 15%;
}
.bar .item a.active {
  text-decoration: underline;
}
.about {
  width: 95%;
  margin: 0 auto;
  text-align: left;
}
.about .content {
  width: 100%;
  float: left;
  font-size: 25px;
}
.about .title {
  font-size: 25px;
  font-weight: 400;
  text-decoration: underline;
}
.about .col.right{
  margin-left: 10%;
  font-size: 20px;
}
.about .content ul {
  list-style: none;
  padding: 0;
  line-height: 200%;
  margin-top: -13px;
}
.about .content p {
}
.about .intro h1 {
  font-weight: 400;
  font-size: 25px;
  line-height:150%;
}
.about .intro h2 {
  font-weight: 400;
  font-size: 25px;
  line-height:150%;
}
.about .intro {
  margin-bottom: 30px;
}
.book_wrap {

}
.book_wrap .subnav {
display:none;
}
.arrow {
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 5px;
  cursor: pointer;
  position: absolute;
  transition:linear 0.2s;
}
.arrow.next {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
  top: 25%;
  right: -70px;
}
.arrow.prev {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
  top: 25%;
  left: -70px;
}
.arrow.down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
.expand_button {display:none;}
.book_wrap .sub_nav {
  position: absolute;
  display:none;
}
.book_wrap .sub_nav .item {
  font-size: 15px;
  display:inline-block;
  vertical-align: middle;
  padding: 20px;
}
.book_wrap .sub_nav .item img {
  display:inline-block;
  vertical-align: middle;
  width: 15px;
  height: 15px;
}
.book_wrap .sub_nav .item img {
  margin-right: 10px;
}
.book {
  position: relative;
  width: 70%;
  height: ;
  margin:0 auto;
  transition: transform 0.5s;
}
.fullscreen-enabled {
  background: black;
}
.fullscreen-enabled .book_outer {
  height: 100vh !important;
}
.fullscreen-enabled .book_inner {
}
.fullscreen-enabled .book {
}
.book_inner {
  width: 200%;
  transform: scale(0.5, 0.5);
  transform-origin: left top;
}
.book_outer {
  width: 100%;
  height: ;
  overflow: hidden;
}
.book.false .arrow {
  opacity: 0;
}
.book.true .arrow {
  opacity: 1;
}
.book_inner {
  overflow:hidden !important;
  position: relative;
}
.paper {
  position: ;
  width: 50% !important;
  top: 0;
  left: 0;
  float: left;
}
#page {
  display: none;
}
#page.active {
  display: block;
}
.front,
.back {
  background-color: white;
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  transform-origin: left;
  transition: transform 0.5s;
}

.front {
  z-index: 1;
  backface-visibility: hidden;
}

.back {
  z-index: 0;
}

.front-content,
.back-content {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.back-content {
  transform: rotateY(180deg)
}

/* Paper flip effect */
.flipped .front,
.flipped .back {
  transform: rotateY(-180deg);
}
button {
  position:absolute;
  z-index:99999;
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.publications, .news {
  width: 95%;
  margin: 0 auto;
  display: grid;
  grid-template-columns: 20% 20% 20% 20% 20%;
  margin-top: 0px;
  margin-bottom: 50px;
}
.news {
  grid-template-columns: 25% 25% 25% 25%;
  grid-column-gap: 0px;
}
.archive {
  width: 95%;
  margin: 0 auto;
  margin-top:20px;
}
.archive .post {
  width: 97%;
  text-align: left !important;
  transition-timing-function: cubic-bezier(0.64, 0.57, 0.67, 1.53);
  transition-duration: 0.2s;
  padding: 0%;
  overflow: hidden;
  vertical-align: middle;
  padding-bottom: 20px;
}
.archive .post p {
  text-align: left !important;

}
.archive .date {
  display:inline-block;
  vertical-align: middle;
  width: 80px;
  text-align: left;
  font-size: 15px;
}
.archive .title {
  display:inline-block;
  vertical-align: middle;
  font-size: 25px;
  padding-right: 20px;
}
.archive.single {
}
.archive.single .post {
  width: 70%;
  margin: 0 auto;
  margin-bottom:30px;
}
.archive.single .post img {
  width: 100%;
  height: auto;
}

/*SQUARESPACE CLEANUP*/
.sqs-image-shape-container-element, .sqs-block-image-figure {
  padding: 0 !important;
  min-height: auto !important;
  margin-block-start: 0px !important;
  margin-block-end: 0px !important;
  margin-inline-start: 0px !important;
  margin-inline-end: 0px !important;
}
.archive.single .post .content button {
  position: static;
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  border: 0;
  outline: none;
}
.v6-visually-hidden {display:none;}
.publications .post, .news .post {
  width: 80%;
  float: ;
  margin: 10%;
  text-align: left;
  transition-timing-function: cubic-bezier(0.64, 0.57, 0.67, 1.53);
  transition-duration: 0.2s;
  -webkit-transition-timing-function: cubic-bezier(0.64, 0.57, 0.67, 1.53);
  -webkit-transition-duration: 0.2s;
}
.news .post {
  width: 85%;
  margin: 7.5%;
}
.publications .post:hover, .news .post:hover {
  transform:scale(1.05);
  transition-duration: 0.2s;
  -webkit-transform:scale(1.05);
  -webkit-transition-duration: 0.2s;
}
h1 {
  font-size: 18px;
  font-weight: 500;
  line-height:150%;
}
.archive .post excerpt {
  font-size: 13px;
}
.post span.time {
  font-size: 15px;
  font-weight: 400;
}
.post .thumb  {
  width: 100%;
  position: relative;
  border: 1px black solid;
}
.post .thumb {
  padding: 0;
  line-height: 0px !important;
  margin-bottom: 30px;
  overflow:hidden;
}
.publications .post .thumb > div {
  padding-bottom: 140%;
  background-size: cover;
  background-position: center center;
}
.news .post .thumb > div {
  padding-bottom: 70%;
  background-size: cover;
  background-position: center center;
}
.publications .post .thumb img, .news .post .thumb img {
  width: 100%;
  height: auto;
  line-height: 0px !important;
}
.publications .post .thumb .number {
  position: absolute;
  background: #fff;
  color: black;
  padding: 15px;
  right: 0;
  bottom:0;
  line-height: 100%;
  border-left: 1px black solid;
  border-top: 1px black solid;
  font-family: jaf-lapture;
  font-size: 12px;
  font-weight: bold;
}
.post.single {
  width: 900px !important;
  margin-top: 100px;
}
.post figure {
  margin: 0;
}
.post.single .meta {
  width: 100%;
  float: ;
  position: ;
  padding-top: 0.5em;
  margin-bottom: 20px;
  text-align: left;
}
.post.single .meta .title, .post.single .meta .date {
  width: auto;
  display: block;
}
.post.single .meta .date {
  margin-top: 10px;
}
.post.single .meta .author {
  margin-top: 10px;
}
.post.single .content {
  width: 100%;
  margin: 0 auto;
  font-size: 18px;
}
/*UI*/
.loader {
  display: block;
  width: 80px;
  height: 80px;
  position: fixed;
  left: 50%;
  top: 50%;
  transform:translate(-50%, -50%);
  z-index: 999999999;
}
.loader:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 4px solid #000;
  border-color: #000 transparent #000 transparent;
  animation: lds-dual-ring 1.2s ease-in-out infinite;
}
/*ANIMATIONS*/
@keyframes lds-dual-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.fade-enter {
  opacity: 0;
  transform: translate(0, 25px);
  z-index: 1;
}
.fade-enter.fade-enter-active {
  opacity: 1;
  transform: translate(0, 0);

  transition: opacity 250ms ease-out, transform 300ms ease;
}
.fade-exit {
  opacity: 1;
  transform: translate(0, 0);
}
.fade-exit.fade-exit-active {
  opacity: 0;
  transform: translate(0, 30px);

  transition: opacity 250ms ease-out, transform 300ms ease;
}
@media (max-width: 1000px) {
  .post.single {
    width: 100% !important;
  }
  body {
    line-height: 200%;
  }
  .publications, .news {
  grid-template-columns: 50% 50%;
  }
  .news .post {
    width: 80%;
    float: ;
    margin: 10%;
  }
  .archive, .about {
    width: 90%;
  }
  .archive .post .title, .archive .post .date {
    display: block;
    padding:0;
  }
  .bar .left, .bar .center {
    width: 100%;
    float: none;
    text-align: center;
  }
  .bar .item {
    display: block;
    width: 100%;
    padding: 0;
    line-height: 200%;
  }
  .bar .right {
    display: none;
  }
  .bar .expand_button {
    position:absolute;
    left: 0px;
    top: 0px;
    display: block;
    padding: 25px;
  }
  .bar .expand_button .arrow {
    border-width: 0 2px 2px 0;
    width: 3px;
    height: 3px;
  }
  .bar .expand_button .arrow.open {
    transform:rotate(-135deg);
  }
  .bar .menu .center {
    transition: ease all 1s;
  }
  .bar .menu.closed .center {
    max-height: 0px !important;
    overflow:hidden;
  }
  .bar .menu.open .center {
    max-height: 200px;
  }
  .book {
    position: absolute;
    width: 100%;
    height: ;
    margin:0 auto;
    transition: transform 0.5s;
  }
  .book_wrap .sub_nav, .book .arrow{display: none;}
  .book_inner {
    width: 100%;
    transform: none;
    transform-origin: none;
  }
  .book_outer {
    width: 100%;
    height: 100%;
    overflow:visible;
  }
}